.social_icons-wrap {
    a {
        border-bottom: none;
        &:hover {
            border-bottom: none;
        }
    }
}

.social-wrap {
    text-align: center;
}

.social_icon-item {
    width: 1.25em;
    height: 1.25em;
    margin-right: 0.25em;
    fill: $color-white;
    &-menu {
        fill: $color-black;
    }
}

.social-wrap-light {
    .social_icon-item {
        fill: $color-grey-dark;
    }
}

;@import "sass-embedded-legacy-load-done:4042";