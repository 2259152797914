.highlights-list, .highlights-img-wrap {
    text-align: center;
    @include mq(tablets) {
        text-align: left;
    }
}

.highlights-list {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
        // font-size: 1.5em;
        display: flex;
        align-items: center;
        margin-bottom: 2em;
        font-family: 'Proxima Nova W01', Helvetica, Arial, sans-serif;
        font-weight: 700;
        &:last-of-type {
            margin-bottom: 0;
        }

        @include mq(tablets) {
            font-size: 1.5em;
        }

    }

    img {
        margin-right: 0.5em;
    }
}
;@import "sass-embedded-legacy-load-done:4049";