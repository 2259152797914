button, .button {
    padding: 0.5em 1em;
    background-color: darken($color-green, 10%);
    color: $color-white;
    border: 2px solid $color-white;
    border-radius: 6px;
    transition: all 0.25s ease;
    &:hover {
        background-color: $color-green;
        cursor: pointer;
    }
}

.button {
    display: inline-block;
}

a.button {
    text-decoration: none;
}

.button-wrap {
    margin-top: 3em;
}

.button-wrap-testimonials {
    margin-top: 1em;
    text-align: center;
}

.button-wrap-right {
    text-align: right;
}
;@import "sass-embedded-legacy-load-done:4036";