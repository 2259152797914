// for the maximum width of the entire content (images + text + etc)
$max-width: 71.25em;
// max width for content (text)
$max-content-width: 42em;

$text-color: #444;
$primary-color: $color-red;
$link-color: $primary-color;

$border-radius: 2px;
;@import "sass-embedded-legacy-load-done:4025";