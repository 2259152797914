.hp_section-heading {
    font-size: 2.5em;
    a:hover {
        text-decoration: none;
    }
}

.section-hp_icons {
    margin: 2em 0;
    padding: 0 0 2em;
    border-top: 2px solid $color-green;
    border-bottom: 2px solid $color-green;
}

.hp-icons_wrap {
    margin: 0 auto;
    padding: 0 1em;
    text-align: center;
    @include mq(tablets) {
        display: flex;
        justify-content: space-between;
    }

    h4 {
        font-weight: 700;
    }
}

.hp-icon-wrap {
    flex: 0 0 100%;
    width: 100%;
    max-width: $max-width;
    margin: 0 auto 3em;
    &:first-of-type {
        margin-top: 3em;
    }
    @include mq(tablets) {
        flex: 0 0 16%;
        width: 16%;
        max-width: 16%;
        margin-right: 3.5em;
        margin-bottom: 0;
        &:first-of-type {
            margin-top: 0;
        }
        &:last-of-type {
            margin-right: 0;
        }
    }
}

.online_packages-content {
    padding: 1em 2em;
}

.hp-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 6em;
    width: 6em;
    max-width: 6em;
    height: 6em;
    margin: 0 auto;
    background-color: $color-white;
    padding: 0.55em;
    border-radius: 50%;

    // @include mq(25em) {
    //     flex: 0 0 4.25em;
    //     width: 4.25em;
    //     max-width: 4.25em;
    //     height: 4.25em;
    //     margin: 1em auto 0;
    // }

    @include mq(40em) {
        flex: 0 0 10em;
        width: 10em;
        max-width: 10em;
        height: 10em;
        margin: 0 auto 0;
        svg {
            width: 6em;
            max-width: 6em;
            height: 6em;
        }
    }

    @include mq(52em) {
        flex: 0 0 12em;
        width: 12em;
        max-width: 12em;
        // height: 12em;
    }
}

.hp_icon-heading {
    line-height: 1.5;
    margin-top: 1em;
    @include mq(25em) {
        font-size: 0.9em;
    }
    @include mq(wide_handhelds) {
        font-size: inherit;
    }

    a {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}

.hp_icon-price {
    margin-top: 0;
    font-style: italic;
}

.jafa-check {
    width: 2.5em;
    height: 2.5em;
}
;@import "sass-embedded-legacy-load-done:4052";