.content-wrap {
    @include clearfix;
    max-width: $max-width;
    margin: 0 auto;
    padding: 1em;

    @include mq(73.25em) {
        padding: 1em 0;
    }

    &-narrow {
        max-width: $max-content-width;
    }

    &-flex {
        @include mq(tablets) {
            display: flex;
            justify-content: space-between;
        }
    }


    &-flex_center {
        @include mq(tablets) {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    &-border {
        margin: 2em auto;
        padding: 2em 1em;
        border-top: 1px solid darken($color-green, 10%);
        border-bottom: 1px solid darken($color-green, 10%);
        @include mq(73.25em) {
            padding: 1em 0;
        }
    }
}

.flex-child-50 {
    max-width: 35em;
    margin: 0 auto;
    img {
        display: block;
        margin: 2em auto;
    }
    @include mq(tablets) {
        flex: 0 0 48%;
        width: 48%;
        max-width: 48%;
        margin: 0;
        &:first-of-type {
            margin-right: 2em;
        }

        img {
            display: initial;
            margin: 0;
        }
    }

}

.flex-child-60 {
    max-width: 35em;
    margin: 0 auto;
    img {
        display: block;
        margin: 2em auto;
    }
    @include mq(tablets) {
        flex: 0 0 58%;
        width: 58%;
        max-width: 58%;
        margin: 0;
        &:first-of-type {
            margin-right: 2em;
        }

        img {
            display: initial;
            margin: 0;
        }
    }
}

.flex-child-40 {
    max-width: 35em;
    margin: 0 auto;
    img {
        display: block;
        margin: 2em auto;
    }
    @include mq(tablets) {
        flex: 0 0 38%;
        width: 38%;
        max-width: 38%;
        margin: 0;
        &:first-of-type {
            margin-right: 2em;
        }

        img {
            display: initial;
            margin: 0;
        }
    }
}

.separator {
    width: 100%;
    height: 2em;
    svg {
        margin: 0 auto;
    }
}

.separator-green {
    fill: darken($color-green, 10%);
}

.separator-left {
    float: left;
    width: 4em;
    height: 2em;
    margin: 0.5em 0.5em 0.5em 0;
    svg {
        margin: 0;
        fill: #444;
    }
}

address {
    margin-top: 0;
}

@import '../common/content_alignment';
;@import "sass-embedded-legacy-load-done:4034";