.logos-wrap {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin: 2em auto 0;
    padding-left: 0;
    text-align: center;

    &:last-of-type {
        margin-bottom: 0;
    }

    @include mq(tablets) {
        margin: 0 auto;
    }
}

.logo {
    flex: 0 0 70%;
    width: 70%;
    max-width: 70%;
    margin: 0 auto 5em;

    @include mq(wide_handhelds) {
        flex: 0 0 40%;
        width: 40%;
        max-width: 40%;
        margin: 0 5% 5em 0;
    }

    @include mq(tablets) {
        flex: 0 0 20.5%;
        width: 20.5%;
        max-width: 20.5%;
        filter: saturate(0);
        transition: filter 0.5s ease;

        &:hover {
            filter: saturate(1);
        }
        &:last-of-type {
            margin-right: 0;
        }
    }
}
;@import "sass-embedded-legacy-load-done:4046";