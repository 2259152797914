.herobar {
    position: relative;
    height: 35em;
    padding-top: 5em;
    background-color: $color-grey-dark;
    text-align: center;

    @include mq(tablets) {
        padding-top: 2em;
    }
}

.no_herobar {
    height: 16em;
    padding-top: 4em;
    background-color: $color-grey-dark;
}

.herobar-hp {
    height: 25rem;
    background: #fff no-repeat;
    background-position: center 90%;
    background-size: 80%;
    background-repeat: no-repeat;
    background-color: #000;
    text-align: center;

    @include mq(tablets) {
        height: 30rem;
        background-position: center;

    }
    @include mq(wide_tablets) {
        background-size: 1000px;
    }
    .header-wrap {
        position: relative;
        z-index: 999;
    }
    .header-social-wrap a {
        position: relative;
        color: $color-white;
        z-index: 999;
    }
    .herobar-buzz {
        z-index: 999;
    }

}

.herobar_hp-overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    background-color: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;

    z-index: 1;
}

.herobar-about {
    background: #fff url('/images/herobars/about-jafa-signs.jpg') no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
}

.herobar-buzz {
    display: none;

    @include mq(tablets) {
        display: block;
        position: absolute;
        right: 10em;
        bottom: 1em;
    }
}

.herobar-gallery-background {
    display: inline-block;
    position: absolute;
    top: 15em;
    flex: 0 0 90%;
    order: 1;
    width: 12.5rem;
    height: 3.5em;
    margin-left: -100px;
    margin-top: 1em;
    padding: 1em 0;
    z-index: 1000;
    transform: skewX(-20deg);
    background-color: rgba($color-white, 0.9);

    @include mq(tablets) {
        position: absolute;
        top: 11em;
        margin: 0 auto 0 22%;
    }

    @include mq(73.25em) {
        top: 11em;
        margin: 0 auto 0 20.5rem;
    }
}

.herobar-gallery-button-wrap {
    margin: 0 auto;
    transform: skewX(20deg);
}


.herobar-gallery-button {

    a {
        color: $color-black;
        font-family: 'Proxima Nova W01', Helvetica, Arial, sans-serif;
        font-weight: 500;
        letter-spacing: 0.9px;

        &:hover {
            color: #97d700;
            text-decoration: none;
            border-bottom: none;
        }
    }

}

;@import "sass-embedded-legacy-load-done:4040";