.footer-wrap {
    max-width: 100%;
    padding: 2em 1em;
    background-color: $color-grey-dark;
    color: $color-green-light;
    text-align: center;

    a {
        color: $color-white;
        text-decoration: none;
        transition: all 0.25s ease;
        &:hover {
            text-decoration: underline;
        }
    }

    a.social_icon-item-link:hover {
        text-decoration: none;
    }

    @include mq(wide_tablets) {
        text-align: left;
    }

    @include mq(73.25em) {
        padding: 2em 0;
    }
}

.footer-flex {
    margin-bottom: 2em;
    @include mq(wide_tablets) {
        display: flex;
        justify-content: space-between;
        margin: 0;

        > div {
            margin-right: 2em;
            &:last-of-type {
                margin-right: 0;
            }
        }
    }
}

.footer {
    max-width: 75em;
    margin: 0 auto;

    .map-wrap .cmsmap {
        margin: 0.5em auto;
        @include mq(wide_tablets) {
            margin: 0;
        }
    }
}

.footer_address-heading {
    margin-top: 0;
}

.footer-social {
    margin-top: 1em;
}

.footer-contact {
    @include mq(wide_tablets) {
        flex: 0 0 20%;
        width: 20%;
        max-width: 20%;
    }
}

div.footer-instagram {
    margin: 0 auto;

    @include mq(wide_tablets) {
        flex: 0 0 36.5%;
        width: 36.5%;
        max-width: 36.5%;
    }
}

.footer-tagline {
    font-size: 1.1em;
    font-style: italic;
}

.footer-links {
    display: flex;
    flex-flow: column nowrap;
    // justify-content: space-between;
    font-size: 0.9em;
    text-align: right;
    @include mq(wide_tablets) {
        flex: 0 0 27%;
        width: 27%;
        max-width: 27%;
    }
}

a.footer-window_logo {
    display: block;
    width: 100%;
    background: $color-grey-light;
    margin-right: 1em;
    padding: 1em;
    color: $text-color;
    text-align: center;
    @include mq(tablets) {
        width: auto;
    }
    img {
        display: block;
        padding-right: 1.25em;
        margin: 0 auto;
        @include mq(tablets) {
            margin: 0;
        }
    }
}

.footer_links-list {
    font-family: 'Proxima Nova W01', Helvetica, Arial, sans-serif;
    list-style: none;
    margin: 0;
    padding: 0;
    li {
        margin-bottom: 0.25em;
    }
}

.footer-copyright {
    font-size: 0.9em;
    margin-top: 1em;
    text-align: right;
}

.footer-copyright-link {
    font-size: 0.8em;
}

.footer-review-top {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 2em 0;

    .image-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;

        .right {
            margin: 0 1em;
        }

        a:hover {
            text-decoration: none;
        }
    }
}
.footer-review-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2em 0 0;
    a:hover {
        text-decoration: none;
    }
}

.footer-3m:nth-child(1) {
    margin-right: .5em;
}

.footer-fortis {
    margin-top: 2rem;
}
;@import "sass-embedded-legacy-load-done:4039";