.testimonial-post {
}

.testimonial-post-border {
    margin-bottom: 2em;
    padding-bottom: 2em;
    border-bottom: 2px solid darken($color-green, 10%);
    &:last-of-type {
        padding-bottom: 0;
        border: none;
    }
}

.testimonial-heading {
    font-size: 1.7em;
    margin-bottom: 0.25em;
}

.testimonial-inner {
    padding: 2.5em;
    border: 3px solid darken($color-green, 10%);
    border-radius: 6px;
}

.testimonial_post-text {
    padding: 1em 0 2em;
    p {
        margin-top: 0;
    }
}

.testimonial-name {
    font-size: 0.9em;
    margin: 1em 0 0;
    font-style: italic;
}

.testimonial-role {
    font-size: 0.8em;

    p:first-of-type {
        margin-top: 0;
    }
}

.testimonial_dots-wrap {
    margin: 0 auto;
    text-align: center;
}

.testimonial-dot {
    width: 1.5em;
    height: 1.5em;
    fill: darken($color-green, 10%);
}

//Vue Slider
// .testimonial-slider-wrap {
//     min-height: 30em;
//     background-color: $color-grey-light;
// }

.testimonial-slider {
    display: none;
    max-width: 100vw;
    overflow-x: hidden;
}

.slick-initialized {
    display: block;
}

.slick-track {
    display: flex;
}

.slick-arrow {
    display: none !important;
}

.slick-dots {
    display: flex;
    justify-content: center;
    padding: 0;
    list-style: none;
    li {
        margin-right: 0.5em;
        &:last-of-type {
            margin-right: 0;
        }
    }
    button {
        display: block;
        width: 1.25em;
        height: 1.25em;
        padding: 0.5em;
        cursor: pointer;
        color: transparent;
        border: 2px solid darken($color-green, 10%);
        border-radius: 50%;
        background: transparent;
    }
}

.slick-active {
    button {
        background-color: darken($color-green, 10%);
    }
}

;@import "sass-embedded-legacy-load-done:4043";