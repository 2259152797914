.cards-wrap {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 2em auto 0;
    padding: 0 2em;
    max-width: 71.25em;

    @include mq(40em) {
        justify-content: flex-start;
    }

    @include mq(73.25em) {
        padding: 0;
    }
}

.card {
    position: relative;
    display: block;
    float: left;
    width: 21em;
    max-width: 21.5em;
    height: 28em;
    margin-bottom: 1em;
    border: 1px solid darken($color-green-light, 10%);
    border-radius: $border-radius;
    transition: all 0.25s ease-in-out;
    overflow: hidden;
    background-color: $color-grey-light;
    box-shadow: 2px 2px 4px rgba($color-green, 0.3);
    border-radius: 4px;

    @include mq(wide_handhelds) {
        flex: 0 0 48.5%;
        width: 48.5%;
        max-width: 48.5%;
        margin-right: 1em;
        &:nth-of-type(2n) {
            margin-right: 0;
        }
    }
    @include mq(tablets) {
        flex: 0 0 31.25%;
        width: 31.25%;
        max-width: 31.25%;
        &:nth-of-type(2n) {
            margin-right: 1em;
        }
        &:nth-of-type(3n) {
            margin-right: 0;
        }
    }

    &:hover {
        border-color: $color-green;
        box-shadow: 4px 4px 8px rgba($color-green, 0.3);
        h2 {
            color: darken($color-green, 10%);
        }
    }
}

.card-short {
    height: 13em;
}

.card-heading {
    font-size: 1.4em;
}

.card-copy {
    position: absolute;
    width: 100%;
    min-height: 7em;
    padding: 1em;
    color: $text-color;
    font-size: 0.85em;
    h2 {
        font-size: 1.25em;
        margin: 0;
        color: $color-green;
    }
}

.card-image {
    width: 100%;
    max-width:  100%;
    display: block;
    margin: 0 auto;
    overflow-y: hidden;

    @include mq(wide_tablets) {
        max-height: 20em;
    }
}

.content-cards-wrap {
    margin-bottom: 2em;
    padding-bottom: 2em;
    border-bottom: 2px solid $color-green;
    .card {
        box-shadow: none;
        &:hover {
            border-color: $color-green;
            box-shadow: 4px 4px 8px rgba($color-green, 0.3);
            h2 {
                color: $color-green;
            }
        }
    }
}
;@import "sass-embedded-legacy-load-done:4045";