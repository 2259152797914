.content_wrap-flex-contact {
    display: flex;
    flex-flow: column nowrap;
    @include mq(tablets) {
        flex-flow: row nowrap;
    }
}

.contact-flex_wrap-address {
    order: 2;
    @include mq(tablets) {
        order: 1;
    }
}

.contact-flex_wrap-form {
    order: 1;
    background: rgba($color-black, 0.1);
    padding: 2em;
    border: 2px solid $color-green;
    border-radius: 8px;
    @include mq(tablets) {
        order: 2;
    }
}

.contact-social_wrap {
    margin-bottom: 2em;
    @include mq(tablets) {
        margin-bottom: 0;
    }
    .social_icon-item {
        fill: $color-green;
        width: 1.6em;
        height: 1.6em;
    }
}
;@import "sass-embedded-legacy-load-done:4053";