#silentbox-group {
    text-align: center;
}

#silentbox-overlay {
    z-index: 9999 !important;
}

.content-wrap h3 {
    text-align: center;
}


.gallery-menu {
    padding: 0;
    display: none;
}

.gallery-page-menu {
    display: none;
    position: fixed;
    top: 5em;
    right: 1em;
    width: 11.5rem;
    height: auto;
    background-color: $color-grey-dark;
    opacity: 0;
    transition: all 1s ease;

    p {
        color: $color-white;
        font-family: 'Proxima Nova W01', Helvetica, Arial, sans-serif;
        font-weight: 500;
        letter-spacing: 0.9;
        text-align: center;
    }

    @include mq(tablets) {
        display: flex;
        position: fixed;
        top: 13em;
        right: 1em;
        margin: auto;
    }
}

.show-menu {
    opacity: 1;
}

.hide-menu {
    opacity: 0;
}

.section-gallery {
    margin-left: -40px;

    @include mq(tablets) {
        margin-left: 0;
    }

}

.gallery-open {
    display: inline-block;
    height: auto;
    background-color: rgba($color-black, 0.8);
    position: absolute;
    top: 18em;
    width: 11.5rem;
    margin-left: -100px;

    @include mq(tablets) {
        position: absolute;
        top: 14.5em;
        margin-left: 22%;
    }

    @include mq(73.25em) {
        margin-left: 20.5rem;
    }
}

.gallery-content-wrap {
    margin: 0 auto;
    transform: skewX(0deg);
}

.gallery-nav {
    font-size: 0.8em;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    padding: 0 1em;

    a {
        color: $color-white;
        font-family: 'Proxima Nova W01', Helvetica, Arial, sans-serif;
        font-weight: 500;
        letter-spacing: 0.9px;

        &:hover {
            color: #97d700;
        }
    }

    li:last-of-type {
        // margin: 0;
    }
}

.gallery-nav_item {
    order: 2;
    display: block;
    margin-right: 0;
    margin-bottom: 0.75em;
}

.gallery-menu-fixed {
    position: fixed;
}
;@import "sass-embedded-legacy-load-done:4047";