// mixins + normalize + common
@import "common/vars",
    "public/vars",
    "common/mixins",
    "public/mixins",
    "common/media",
    "common/common";

// global styles on the most common elements
html {
    @include default-html-style;
}

a {
    color: rgb(112,153,12);
    text-decoration: none;
    transition: all 0.25s ease;
    &:hover {
        text-decoration: underline;
        color: darken($color-green, 10%);
    }
}
address { font-style: normal; }

// load common styles
@import "common/forms",
    //"common/pagination",
    "common/alerts",
    //"common/modal",
    "common/ie9_warning";

// public only styles
@import "public/content",
    "public/buttons",
    "public/headings",
    "public/header",
    "public/footer",
    "public/herobar",
    "public/section",
    "public/social",
    "public/testimonials",
    "public/map",
    "public/cards",
    "public/logos",
    "public/gallery",
    "public/infographic",
    "public/highlights",
    "public/window",
    "public/video"
    ;

// page specific
@import "public/p_index", "public/p_contact";
