.header-wrap {
    display: flex;
    flex-flow: column nowrap;
    color: $color-white;
    width: 100%;
    max-width: 100%;
    a {
        color: $color-black;
        text-decoration: none;
        &:hover {
            color: $color-green;
            border-bottom: 1px solid $color-green;
        }
    }

    @include mq(tablets) {
        display: block;
    }
}

.header {
    position: relative;
    flex: 0 0 85%;
    width: 85%;
    max-width: 85%;
    height: 2.5em;
    margin: 0 auto;
    padding: 1em 0;
    background-color: rgba($color-white, 0.9);
    z-index: 1000;
    order: 1;

    @include mq(tablets) {
        height: 3.25em;
        margin-top: 0.5em;
        flex: 0 0 90%;
        width: 90%;
        max-width: 90%;
        transform: skewX(-20deg);
    }
    @include mq(73.25em) {
        width: 90%;
        max-width: $max-width;
    }
}

.header-3m {
    margin-right: 1em;
    padding-top: 0.75em;
}

.header-social-wrap {
    display: flex;
    justify-content: center;
    order: 2;
    max-width: 73.25em;
    @include mq(tablets) {
        justify-content: flex-end;
    }
    .social_icons-wrap {
        max-width: 14em;
        margin-bottom: 5.05em;
        margin: 1em auto 0;
        text-align: center;

        @include mq(tablets) {
            margin-right: 2em;
            margin-left: auto;
            text-align: right;
        }
        @include mq($max-width) {
            margin-right: 0;
        }
    }



    .social_icon-item {
        background-color: rgba($color-black, 0.6);
        border-radius: 3px;
        transition: all 0.25s ease;
        &:hover {
            fill: $color-green-light;
        }
    }

    @include mq(tablets) {
        margin: 0 auto;
    }

    h4 {
        text-align: center;
        margin: 0;
        @include mq(tablets) {
            text-align: right;
        }
    }

    a:hover {
        border:none;
        text-decoration: none;
    }
}

.header-content-wrap {
    @include mq(tablets) {
        transform: skewX(20deg);
    }
}

.header-logo {
    position: absolute;
    bottom: -0.35em;
    left: -1.65rem;
    max-width: 15em;
    @include mq(tablets) {
        bottom: -1.25em;
        left: -1.2em;
        max-width: 18.75em;
    }
}

.header-nav {
    margin-top: 0;
    margin-bottom: 0;
    text-align: right;
    padding: 0;
    list-style: none;
}

.header-nav_item {
    display: none;
    font-family: 'Proxima Nova W01', Helvetica, Arial, sans-serif;
    font-weight: 500;
    letter-spacing: 0.9px;
    @include mq(wide_tablets) {
        font-size: 0.9em;
        display: inline-block;
        margin-right: 1.5em;
    }
}

.header-nav_item-home {
    display: none;
    @include mq($max-width) {
        display: inline-block;
    }
}

.header-nav_item-menu {
    margin-right: 1em;
    margin-top: -0.25em;
    @include mq(wide_tablets) {
        display: none;
    }
}

.header_mobile-open {
    padding: 0;

    .header {
        height: auto;
        background-color: $color-white;
        transform: skewX(0deg);
    }

    .header-logo {
        position: absolute;
        top: -7em;
        @include mq(tablets) {
            top: -8em;
        }
    }

    .header-content-wrap {
        margin: 0 auto;
        transform: skewX(0deg);
    }

    .header-nav {
        display: flex;
        flex-flow: column nowrap;
        width: 100%;
        padding: 0 1em;
    }
    .header-nav_item {
        order: 2;
        display: block;
        margin-right:0;
        margin-bottom: 0.75em;
    }
    .header-nav_item-menu {
        order: 1;
        text-align: right;
        margin-right: 0;
        z-index: 9999;
    }
}

;@import "sass-embedded-legacy-load-done:4038";