h1, h2, h3, h4, h5, h6 {
    font-family: 'Proxima Nova W01', Helvetica, Arial, sans-serif;
    line-height: 1.2;
}

h2, h3, h4, h5 {
    margin-bottom: 0.25em;
}

h1 {
    font-size: 2.25em;
    @include mq(tablets) {
        font-size: 3em;
    }

}
;@import "sass-embedded-legacy-load-done:4037";