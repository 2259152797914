@mixin flex-row_wrap {
    display: flex;
    flex-flow: row wrap;
}

@mixin flex-column_wrap {
    display: flex;
    flex-flow: column wrap;
}

// Clearfix from: https://css-tricks.com/snippets/css/clear-fix/ (August 2012)
@mixin clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}
;@import "sass-embedded-legacy-load-done:4026";