/* global variables */
$color-white: rgb(255, 255, 255);
$color-black: rgb(0, 0, 0);
$color-grey-light: rgb(243, 243, 243);
$color-grey-dark: rgb(32, 36, 41);
$color-red: rgb(196, 90, 75);
$color-red-light: rgb(183, 69, 60);
// $color-green: rgb(1, 142, 66);

$color-green: rgb(151, 215, 0);
$color-green-light: rgb(206, 220, 0);

//these are used in multiple situations - not just alerts
$status-type-success: rgb(90, 182, 90);
$status-type-info: rgb(91, 192, 221);
$status-type-warning: rgb(240, 173, 78);
$status-type-danger: rgb(217, 82, 78);

$pagination-link-hover: rgba(32, 36, 41, 0.1);
$pagination-current-border: rgba(32, 36, 41, 0.15);

$form-space-separator: 0.25em;

$mqs: (
    handhelds: 20em,
    wide_handhelds: 35em,
    tablets: 48em,
    wide_tablets: 64em,
);
;@import "sass-embedded-legacy-load-done:4024";